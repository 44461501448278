import VuexORM from "@vuex-orm/core"
import AdminUser from "~/models/AdminUser"
import BOIRSubmission from "~/models/BOIRSubmission"
import Card from "~/models/Card"
import Category from "~/models/Category"
import Charge from "~/models/Charge"
import Chargeback from "~/models/Chargeback"
import Company from "~/models/Company"
import CompanyFiles from "~/models/CompanyFiles"
import CompanyMail from "~/models/CompanyMail"
import ComplianceAmendment from "~/models/ComplianceAmendment"
import ComplianceGuard from "~/models/ComplianceGuard"
import ComplianceItem from "~/models/ComplianceItem"
import ComplianceOrder from "~/models/ComplianceOrder"
import CurrentUser from "~/models/CurrentUser"
import DayStats from "~/models/DayStats"
import Document from "~/models/Document"
import Experiment from "~/models/Experiment"
import ForeignEntity from "~/models/ForeignEntity"
import Form from "~/models/Form"
import Label from "~/models/Label"
import Lander from "~/models/Lander"
import Note from "~/models/Note"
import Notification from "~/models/Notification"
import OrderStatusTemplate from "~/models/OrderStatusTemplate"
import Orders from "~/models/Order"
import Payload from "~/models/Payload"
import PaymentMethod from "~/models/PaymentMethod"
import Plan from "~/models/Plan"
import Product from "~/models/Product"
import ProductVersion from "~/models/ProductVersion"
import Refund from "~/models/Refund"
import Session from "~/models/Session"
import StateInfo from "~/models/StateInfo"
import Stats from "~/models/Stats"
import StripeAlerts from "~/models/StripeAlerts"
import Subscription from "~/models/Subscription"
import Task from "~/models/Task"
import TaskRecipe from "~/models/TaskRecipe"
import TasksCounter from "~/models/TasksCounter"
import Team from "~/models/Team"
import User from "~/models/User"

const database = new VuexORM.Database()

database.register(AdminUser)
database.register(BOIRSubmission)
database.register(Card)
database.register(Category)
database.register(Charge)
database.register(Chargeback)
database.register(Company)
database.register(CompanyFiles)
database.register(CompanyMail)
database.register(ComplianceAmendment)
database.register(ComplianceOrder)
database.register(ComplianceGuard)
database.register(ComplianceItem)
database.register(CurrentUser)
database.register(DayStats)
database.register(Document)
database.register(Experiment)
database.register(ForeignEntity)
database.register(Form)
database.register(Label)
database.register(Lander)
database.register(Note)
database.register(Notification)
database.register(Orders)
database.register(OrderStatusTemplate)
database.register(Payload)
database.register(PaymentMethod)
database.register(Plan)
database.register(Product)
database.register(ProductVersion)
database.register(Refund)
database.register(Session)
database.register(StateInfo)
database.register(Stats)
database.register(StripeAlerts)
database.register(Subscription)
database.register(Task)
database.register(TaskRecipe)
database.register(TasksCounter)
database.register(Team)
database.register(User)

export default database
