import BaseModel, { EntityType } from "./BaseModel"
import type { Product } from "~/interfaces/api/Products.interface"

import type {
  OrderStatusTemplate as OrderStatusTemplateInterface
} from "~/interfaces/api/OrderStatusTemplate.interface"
import type { StatusLink } from "~/interfaces/api/StatusLink.interface"

export default class OrderStatusTemplate
  extends BaseModel
  implements OrderStatusTemplateInterface {
  declare status_links: StatusLink[] | null
  declare id?: number
  declare title: string
  declare product_id?: number
  declare product: Product
  declare description: string
  declare status_type: string
  declare percentage: number
  declare send_email: boolean
  declare email_message: string
  declare requires_user_action: "yes" | "no"
  declare mark_order_complete: boolean
  declare created_at?: string
  declare updated_at?: string

  static entity = EntityType.orderStatusTemplate

  static fields() {
    return {
      id: this.number(0),
      title: this.string(""),
      product_id: this.attr(null),
      product: this.attr(null),
      description: this.string(""),
      status_links: this.attr([]),
      status_type: this.string(""),
      percentage: this.number(0),
      send_email: this.boolean(false),
      email_message: this.attr(null),
      requires_user_action: this.string(""),
      mark_order_complete: this.boolean(false),
      created_at: this.string(""),
      updated_at: this.string("")
    }
  }
}
