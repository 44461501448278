import BaseModel, { EntityType } from "./BaseModel"
import Charge from "./Charge"
import Company from "./Company"
import Document from "./Document"
import type Notes from "~/models/Note"
import Order from "./Order"
import PaymentMethod from "./PaymentMethod"
import Session from "./Session"
import Subscription from "./Subscription"
import type { User as UserInterface } from "~/interfaces/api/Users.interface"
import format from "date-fns/format"

export default class User extends BaseModel implements UserInterface {
  static entity = EntityType.users
  static primaryKey = "id"

  static fields() {
    return {
      charges: this.hasMany(Charge, "user_id"),
      companies: this.hasMany(Company, "user_id"),
      default_payment_method_id: this.number(0),
      documents: this.hasMany(Document, "user_id"),
      editor_controls: this.boolean(false),
      email: this.string(""),
      id: this.number(0),
      name: this.string(""),
      notes: this.attr(""),
      orders: this.hasMany(Order, "user_id"),
      payment_method_ids: this.attr([]),
      payment_methods: this.hasManyBy(PaymentMethod, "payment_method_ids"),
      phone: this.number(0),
      plus_active: this.boolean(false),
      plus_active_until: this.attr(""),
      pricing_set: this.attr(""),
      revenue: this.number(0),
      sessions: this.hasMany(Session, "user_id"),
      subscriptions: this.hasMany(Subscription, "user_id")
    }
  }

  get plus_active_until_Mdyhm() {
    if (this.plus_active_until) {
      const date = new Date(this.plus_active_until)
      return format(date, "MM/dd/yyyy HH:mm")
    }
  }

  declare created_at: Date
  declare default_payment_method_id: number
  declare email: string
  declare editor_controls: boolean
  declare id: number
  declare name: string
  declare phone: number | null
  declare plus_active: boolean
  declare plus_active_until: Date | null
  declare pricing_set: {
    annual_plan_id: number
    compliance_guard_plan_id: number
    monthly_plan_id: number
    registered_agent_plan_id: number
  }
  declare pricing_set_id: number
  declare notes: Notes[]
  declare revenue: number
  declare updated_at: Date
}
