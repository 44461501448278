import BaseModel, { EntityType } from "./BaseModel"
import User from "./User"
import Refund from "./Refund"
import type { Charge as ChargeInterface } from "~/interfaces/api/Charges.interface"

export default class Charge extends BaseModel implements ChargeInterface {
  static entity = EntityType.charges
  static primaryKey = "id"

  static state() {
    return { total: null }
  }

  static fields() {
    return {
      amount: this.number(0),
      amount_refunded: this.number(0),
      billing_info_object: this.attr(""),
      card_type: this.attr(""),
      chargeable_id: this.attr(""),
      chargeable_type: this.attr(""),
      chargebacks: this.attr(""),
      company_id: this.number(0),
      created_at: this.attr(""),
      id: this.number(0),
      last_four: this.attr(""),
      line_items: this.attr(""),
      name: this.string(""),
      recurly_id: this.string(""),
      refundable: this.attr(""),
      refunded_at: this.string(""),
      refunds: this.hasMany(Refund, "charge_id"),
      stripe_id: this.string(""),
      updated_at: this.attr(""),
      user: this.belongsTo(User, "user_id"),
      user_id: this.number(0)
    }
  }

  get amount_formatted() {
    return `$${this.amount.toFixed(2)}`
  }

  declare amount: number
  declare amount_refunded: number
  declare billing_info_object: string
  declare chargebacks: {
    id: number
    created_at: Date
  }[]

  declare created_at: Date
  declare id: number
  declare line_items: object[]
  declare name: string
  declare refunded_at: string
  declare recurly_id: string
  declare refunds: Refund[]
  declare stripe_id: string
  declare updated_at: Date
  declare user_id: number
}
