import BaseModel, { EntityType } from "./BaseModel"

import type { ComplianceGuard as ComplianceGuardInterface } from "~/interfaces/api/Companies.interface"

export default class ComplianceGuard
  extends BaseModel
  implements ComplianceGuardInterface {
  declare id: number
  declare name: string
  declare created_at: Date
  declare updated_at: Date
  declare company_id: number
  declare start_date: Date
  declare due_date: Date
  declare status: string
  static entity = EntityType.complianceguard

  static fields() {
    return {
      activated_at: this.string(""),
      id: this.number(0),
      available_amendments: this.attr(""),
      canceled_at: this.attr(""),
      company_id: this.attr(""),
      compliance_items: this.attr(""),
      compliance_orders: this.attr(""),
      created_at: this.attr(""),
      recurly_subscription_id: this.attr(""),
      resource_type: this.attr(""),
      updated_at: this.attr(""),
      user_id: this.attr("")
    }
  }
}
