import type Company from "~/models/Company"
import type PaymentMethod from "~/models/PaymentMethod"
import type Plan from "~/models/Plan"
import type User from "~/models/User"

export enum ValidStatus {
  active = "active",
  incomplete = "incomplete",
  past_due = "past_due",
  paused = "paused",
  trialing = "trialing"
}

export enum InvalidStatus {
  canceled = "canceled",
  incomplete_expired = "incomplete_expired",
  legacy_canceled = "legacy_canceled",
  unpaid = "unpaid"
}

export interface Subscription {
  id: number
  cancel_at: Date | null
  cancel_at_period_end: boolean | null
  canceled_at: Date | null
  company: null | Company
  company_id: null | number
  created_at: Date
  current_period_end: Date
  current_period_start: Date
  default_payment_method: null | PaymentMethod
  default_payment_method_id: null | number
  ended_at: Date | null
  plan: Plan
  plan_id: number
  start_date: Date
  status: ValidStatus | InvalidStatus
  trial_end: Date | null
  trial_start: Date | null
  updated_at: Date
  user: User
  user_id: number
}
