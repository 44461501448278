import BaseModel, { EntityType } from "./BaseModel"
import Subscription from "./Subscription"
import User from "./User"
import type {
  BillingDetails,
  PaymentMethod as PaymentMethodInterface,
  PaymentType,
  PaymentTypeDetails
} from "~/interfaces/api/PaymentMethods.interface"

export default class PaymentMethod extends BaseModel implements PaymentMethodInterface {
  static entity = EntityType.paymentMethods
  static primaryKey = "id"

  static fields() {
    return {
      billing_details: this.attr({}),
      created_at: this.attr(null),
      id: this.number(null),
      payment_type: this.string("card"),
      payment_type_details: this.attr({}),
      stripe_id: this.string(""),
      subscription_ids: this.attr([]),
      subscriptions: this.hasManyBy(Subscription, "subscription_ids"),
      updated_at: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      user_id: this.number(null)
    }
  }

  declare id: number
  declare billing_details: BillingDetails
  declare created_at: Date
  declare payment_type: PaymentType
  declare payment_type_details: PaymentTypeDetails
  declare updated_at: Date
  declare stripe_id: string
  declare subscription_ids: number[]
  declare subscriptions: Subscription[]
  declare user: User
  declare user_id: number
}
