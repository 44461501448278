import BaseModel, { EntityType } from "./BaseModel"
import Charge from "./Charge"
import type { Refund as RefundInterface } from "~/interfaces/api/Refunds.interface"

export default class Refund extends BaseModel implements RefundInterface {
  static entity = EntityType.refunds
  static primaryKey = "id"

  static fields() {
    return {
      admin_id: this.number(null).nullable(),
      amount: this.number(null),
      charge: this.belongsTo(Charge, "charge_id"),
      charge_id: this.attr(null),
      created_at: this.attr(""),
      id: this.number(0),
      refunded_at: this.attr(""),
      state: this.string("pending"),
      updated_at: this.attr("")
    }
  }

  declare admin_id: number | null
  declare amount: number
  declare charge_id: number
  declare created_at: Date
  declare id: number
  declare refunded_at: Date
  declare state: string
  declare updated_at: Date
}
