import BaseModel, { EntityType } from "./BaseModel"
import Company from "./Company"
import PaymentMethod from "./PaymentMethod"
import Plan from "./Plan"
import User from "./User"
import type { Subscription as SubscriptionInterface } from "~/interfaces/api/Subscriptions.interface"
import { ValidStatus, InvalidStatus } from "~/interfaces/api/Subscriptions.interface"

export default class Subscription extends BaseModel implements SubscriptionInterface {
  static entity = EntityType.subscriptions
  static primaryKey = "id"
  static statuses = { ...ValidStatus, ...InvalidStatus }
  static validStatuses = ValidStatus
  static inValidStatuses = InvalidStatus

  static fields() {
    return {
      cancel_at: this.attr(null).nullable(),
      cancel_at_period_end: this.boolean(false).nullable(),
      canceled_at: this.attr(null).nullable(),
      company: this.belongsTo(Company, "company_id"),
      company_id: this.number(null),
      created_at: this.attr(null).nullable(),
      current_period_end: this.attr(null),
      current_period_start: this.attr(null),
      default_payment_method: this.belongsTo(PaymentMethod, "default_payment_method_id"),
      default_payment_method_id: this.number(null),
      ended_at: this.attr(null).nullable(),
      id: this.number(null),
      plan: this.belongsTo(Plan, "plan_id"),
      plan_id: this.number(null),
      start_date: this.attr(null),
      status: this.string(""),
      trial_end: this.attr(null).nullable(),
      trial_start: this.attr(null).nullable(),
      updated_at: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      user_id: this.number(null)
    }
  }

  isValid() {
    return this.status in Subscription.validStatuses
  }

  declare id: number
  declare cancel_at: Date | null
  declare cancel_at_period_end: boolean | null
  declare canceled_at: Date | null
  declare company: null | Company
  declare company_id: null | number
  declare created_at: Date
  declare current_period_end: Date
  declare current_period_start: Date
  declare default_payment_method: null | PaymentMethod
  declare default_payment_method_id: null | number
  declare ended_at: Date | null
  declare plan: Plan
  declare plan_id: number
  declare start_date: Date
  declare status: ValidStatus | InvalidStatus
  declare trial_end: Date | null
  declare trial_start: Date | null
  declare updated_at: Date
  declare user: User
  declare user_id: number
}
