import BaseModel, { EntityType } from "./BaseModel"
import User from "./User"
import Company from "./Company"

import type { Order as OrderInterface } from "~/interfaces/api/Orders.interface"
import type { StatusData } from "~/interfaces/api/StatusData.interface"

export default class Order extends BaseModel implements OrderInterface {
  declare abandoned_at: Date
  declare id: number
  declare name: string
  declare state: string
  declare status: string
  declare order_statuses: StatusData[]
  declare order_type: string
  declare entity_type: string
  declare purchased_at: Date
  declare canceled_at: Date
  declare processing_completed_at: Date
  declare purchased_ip: string
  declare company_id: number
  declare step: number
  declare total_cost: number
  declare total_revenue: number
  declare user_id: number
  declare line_items: object[]
  declare data: object
  declare attachments: object[]
  declare labels_assignments: object[]

  static entity = EntityType.orders

  static state() {
    return {
      total: null
    }
  }

  static fields() {
    return {
      id: this.number(0),
      company_name: this.string(""),
      state: this.string(""),
      status: this.string(""),
      order_statuses: this.attr([]),
      order_type: this.string(""),
      entity_type: this.string(""),
      purchased_at: this.attr(""),
      canceled_at: this.attr(""),
      abandoned_at: this.attr(""),
      processing_completed_at: this.attr(""),
      purchased_ip: this.string(""),
      company_id: this.number(0),
      company: this.belongsTo(Company, "company_id"),
      step: this.number(0),
      total_cost: this.number(0),
      total_revenue: this.number(0),
      user_id: this.number(0),
      user: this.belongsTo(User, "user_id"),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      line_items: this.attr(""),
      data: this.attr(""),
      attachments: this.attr(""),
      labels_assignments: this.attr("")
    }
  }

  get total_cost_formatted() {
    return "$" + this.total_cost.toFixed(2)
  }

  get total_revenue_formatted() {
    return "$" + this.total_revenue.toFixed(2)
  }
}
